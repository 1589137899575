import { Routes, Route } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/home-page/HomePage.jsx";
import LegalPage from "./pages/legal-notices/LegalNotices.jsx";
import AdoPage from "./pages/ado-page/AdoPage.jsx";
import KidsPage from "./pages/kids-page/KidsPage.jsx";
import BookingPage from "./pages/booking-page/BookingPage.jsx";

import ValidationPage from "./pages/validation-page/ValidationPage";

import Cart from "./pages/Cart/Cart.jsx";


function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/ado" element={<AdoPage />} />
      <Route path="/kids" element={<KidsPage />} />
      <Route path="/reservation" element={<BookingPage />} />
      <Route path="/mentions-legales" element={<LegalPage />} />
      <Route path="/validation" element={<ValidationPage />} />
      <Route
        path="/mentions-legales/accessibilite"
        element={<LegalPage activeTabIndex={3} />}
      />
      <Route  path="/cart" element={<Cart />} />
    </Routes>
  );
}

export default App;
