import "./HomePage.css";
import HeroSection from "./home-sections/hero-section/HeroSection";
import IntroSection from "./home-sections/intro-section/IntroSection";
import PresentationSection from "./home-sections/presentation-section/PresentationSection";
import HaciendaSection from "./home-sections/hacienda-section/HaciendaSection";
import CategorieSection from "./home-sections/categorie-section/CategorieSection";
import FormationSection from "./home-sections/formation-section/FormationSection";
import AvisSection from "./home-sections/avis-section/AvisSection";
import BannerSection from "./home-sections/banner-section/BannerSection";
import FaqSection from "./home-sections/faq-section/FaqSection";
import ContactSection from "./home-sections/contact-section/Contact";
import VideoSection from "./home-sections/video-section/VideoSection";

function HomePage() {
  return (
    <>
      <div className="home-page-wrapper">
        <HeroSection />
        <IntroSection />
        <PresentationSection />
        <HaciendaSection />
        <CategorieSection />
        <VideoSection />
        <FormationSection />
        {/* <AvisSection /> */}
        <BannerSection />
        <FaqSection />
        <ContactSection />
      </div>
    </>
  );
}

export default HomePage;
