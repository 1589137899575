import { useRef, useEffect, useState } from "react";
import "./PresentationSection.css";
import KidsImage1 from "../../../../media/images/kids-section-image-1.jpg";
import KidsImage2 from "../../../../media/images/kids-section-image-2.jpg";
import DevIcon from "../../../../media/icons/development-icon.png";
import DesignIcon from "../../../../media/icons/design-icon.png";
import IaIcon from "../../../../media/icons/ia-icon.png";

function PresentationSection() {
  /*animation d'apparition sur intersection*/
  const sectionRef = useRef();
  const [isSectionIntersected, setIsSectionIntersected] = useState();
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsSectionIntersected(entry.isIntersecting);
    });
    observer.observe(sectionRef.current);
    return () => {
      observer.disconnect();
    };
  });

  return (
    <div className="home-sections-wrapper">
      <div
        ref={sectionRef}
        className={`presentation-section-container ${
          isSectionIntersected ? "visible" : ""
        }`}
      >
        <div className="presentation-section-illustration">
          <img
            className="presentation-section-image"
            src={KidsImage2}
            alt="team work image"
          ></img>
          <div className="presentation-section-container-circle">
            <img
              className="presentation-section-image-circle"
              src={KidsImage1}
              alt="team work image"
            ></img>
          </div>
        </div>
        <div className="presentation-section-description">
          <div className="presentation-section-item">
            <img src={DevIcon} width={"32px"} height={"32px"}></img>
            <div className="presentation-section-item-body">
              <h5>Développement web</h5>
              <p>
                Les enfants/ados apprendront les bases de la création de sites
                web, notamment la conception de pages avec HTML, CSS. Ils
                pourront créer leur propre site et découvrir les coulisses
                du monde numérique.
              </p>
            </div>
          </div>
          <div className="presentation-section-item">
            <img src={DesignIcon} width={"32px"} height={"32px"}></img>
            <div className="presentation-section-item-body">
              <h5>Design</h5>
              <p>
                Les enfants/ados découvriront les outils de création graphique
                pour élaborer des designs uniques et attrayants. Ils pourront
                laisser libre cours à leur créativité en concevant des affiches,
                des logos et bien plus encore.
              </p>
            </div>
          </div>
          <div className="presentation-section-item">
            <img src={IaIcon} width={"32px"} height={"32px"}></img>
            <div className="presentation-section-item-body">
              <h5>Intelligence artificielle</h5>
              <p>
                Nous initierons les enfants/ados aux concepts de base de
                l’intelligence artificielle, tels que l’apprentissage
                automatique et la reconnaissance d’images. Ils pourront créer
                leurs propres projets d’IA et explorer les possibilités
                de cette technologie émergente.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PresentationSection;
