import "./Tabs.css";
import logo from "../../../media/images/logo_black_wyze.png";
import { tabs } from "../tabs.js";
import { tabChapters } from "../tabChapters.js";

export default function Tabs({ children, activeTabIndex, setActiveTabIndex }) {
  return (
    <>
      <div className="tabs">
        <div className="tabs-header">
          {children.map((tab, index) => (
            <div
              key={index}
              className={`tab-header-item ${
                activeTabIndex === index ? "active" : ""
              }`}
              onClick={() => setActiveTabIndex(index)}
            >
              {tab.props.title}
            </div>
          ))}
          <Logo />
        </div>
        <SideBar activeTab={activeTabIndex} />
        <div className="tab-content">{children[activeTabIndex]}</div>
      </div>
    </>
  );
}

function Logo() {
  return (
    <>
      <div className="legal-notices-logo-container">
        <a href="/">
          <img src={logo} alt="Logo Wyze" className="logo-image" />
        </a>
      </div>
    </>
  );
}

function SideBar({ activeTab }) {
  const chapters = tabChapters[tabs[activeTab]];
  console.log("chapters", chapters);
  console.log("activeTab", activeTab);

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <p>Chapitres</p>
      </div>
      <div className="sidebar-content">
        <ul>
          {chapters?.map((chapter, idx) => {
            return (
              <li key={`chapter-${idx}-${activeTab}`}>
                <a href={`#${chapter?.sectionId}`}>{chapter?.chapterName}</a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
