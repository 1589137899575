// import React from "react";
// import ReactDOM from "react-dom/client";
// import { BrowserRouter as Router } from "react-router-dom";
//
// import App from "./App.jsx";
// import "./index.css";
// import Footer from "./components/Footer/Footer.jsx";
// import ScrollToTop from "./components/ScrollToTop/ScrollToTop.jsx";
// import Cookie from "./components/CookiesConsent/Cookie.jsx";
// import BackToTopButton from "./components/CookiesConsent/BackToTop.jsx";
// import Navbar from "./components/Navbar/Navbar.jsx";
//
// ReactDOM.createRoot(document.getElementById("root")).render(
//   <React.StrictMode>
//     <Router>
//       <ScrollToTop />
//       <Navbar />
//       <App />
//       <Footer />
//       <Cookie />
//       <BackToTopButton />
//     </Router>
//   </React.StrictMode>
// );
import React, {useState, useEffect, createContext, useContext} from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App.jsx";
import "./index.css";
import Footer from "./components/Footer/Footer.jsx";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop.jsx";
import Cookie from "./components/CookiesConsent/Cookie.jsx";
import BackToTopButton from "./components/CookiesConsent/BackToTop.jsx";
import Navbar from "./components/Navbar/Navbar.jsx";
import secureLocalStorage from "react-secure-storage";
import AppContext from "./context";

function Root() {

    return (
        <React.StrictMode>
            <Router>
                <ScrollToTop />
                <AppContext>
                    <Navbar />
                    <App />
                    <Footer />
                    <Cookie />
                    <BackToTopButton />
                </AppContext>
            </Router>
        </React.StrictMode>
    );
}

ReactDOM.createRoot(document.getElementById("root")).render(<Root />);
