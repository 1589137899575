import React from "react";
import "./CgvSection.css";
import retractionForm from "../../../media/fichier/formulaire_de_rétractation.pdf";
import Strie from "../../../components/Strie/Strie";

export default function CgvPage() {
  return (
    <div className="cgv-wrapper">
      <div className="cgv-container">
        <div className="cgv-header">
          <h1>Conditions générales de vente</h1>
        </div>
        <div className="cgv-body">
          <div className="cgv-section" id="cgv-definitions">
            <h2 className="cgv-section-title">Définitions</h2>
            <p className="cgv-section-text">
              <span className="bold subtitle-color">
                « Bulletin d'inscription »
              </span>{" "}
              : Désigne le document, constituant une partie du Contrat, rempli
              et signé par l'Apprenant s'inscrivant à titre individuel.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold subtitle-color">
                « Conditions Générales de Vente (CGV) »
              </span>{" "}
              : Désignent le présent document, constituant une partie du
              Contrat.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold subtitle-color">
                « Conseiller en Formation »
              </span>{" "}
              : Désigne la personne représentant l'Ecole, ayant effectivement
              conseillé l'Apprenant pour la conclusion du Contrat.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold subtitle-color">« Contrat » </span>: Désigne
              l'ensemble composé du Bulletin d'inscription et du Programme de
              formation et les présentes Conditions Générales de Ventes (« CGV
              »).
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold subtitle-color">« Nous » </span> /
              <span className="bold subtitle-color"> « Wyze Kids » </span> /
              <span className="bold subtitle-color">« Ecole » </span>: Désignent
              la société par actions simplifiée « Wyze Academy » au capital de 2
              €, immatriculée au TCO de Bobigny (SIRET 98 47 84 64 500017), et dont
              le siège social est situé au 21 avenue Raspail–93420 VILLEPINTE
              (numéro de TVA FR18984784645). Wyze Kids est représentée par M.
              Bertrand DETRE, son Président Directeur Général et M. Demba
              SOUMARE, son Directeur Général.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold subtitle-color">« Parties » </span> :
              Désignent Vous et Nous pris ensemble.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold subtitle-color">
                « Programme de formation »
              </span>{" "}
              : Désigne le document, constituant une partie du Contrat,
              établissant le programme de la formation de l'Apprenant.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold subtitle-color">« Service(s) » </span> :
              Désigne-le(s) service(s) mis à disposition par Nous, à savoir les
              prestations décrites aux articles 3 à 6, en ce inclus des
              prestations de formation.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold subtitle-color">« Site » </span> : Désigne,
              à la date des présentes, le site dont l'URL est{" "}
              <a
                className="cgv-section-link"
                href="/"
                aria-label="Cliquer ici pour être redirigé vers la page d'accueil"
              >
                www.wyze-kids.com
              </a>
              . Il s'agit du Site hébergeant le Service. Il est entendu que si
              le Service devait être hébergé sur un autre lien URL, il serait
              raisonnable de considérer que le terme « Site » renverrait vers
              cet autre lien URL.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold subtitle-color">« Vous »</span> /
              <span className="bold subtitle-color"> « Apprenants »</span> :
              Désigne toute personne physique bénéficiant du Service.
            </p>
            <br />
            <Strie scale={5} culor="red" left="-20%" top="20%" zindex={-1} />
            <Strie scale={2} culor="red" left="-19%" top="140%" zindex={-1} />
            <Strie scale={6} culor="blue" left="115%" top="40%" zindex={-1} />
          </div>
          <p className="cgv-section-text">
            Les présentes CGV s'appliquent aux Services achetés par l'Apprenant.
          </p>
          <br />
          <p className="cgv-section-text">
            Wyze Kids se réserve la possibilité de modifier ou mettre à jour ses
            CGV à tout moment, après en avoir préalablement informé l'Apprenant.
            Les CGV applicables sont celles disponibles et consultables sur le
            Site au jour de l'inscription à l'exclusion de toutes autres.
          </p>
          {/* <br /> */}
          <div className="cgv-section" id="cgv-presentation">
            <h2 className="cgv-section-title">1. Présentation</h2>
            <p className="cgv-section-text">
              Wyze Kids est un organisme de formation enregistré sous le numéro
              de déclaration d’activité 11 93 10759 93 auprès du préfet de la
              région d’Île de France, dont les services sont consultables sur le
              Site{" "}
              <a
                className="cgv-section-link"
                href="/"
                aria-label="Cliquer ici pour être redirigé vers la page d'accueil"
              >
                www.wyze-kids.com
              </a>{" "}
              . Les Directeurs de la publication du site et de l’organisme de
              formation sont <span className="bold">M. Bertrand DETRE</span> et{" "}
              <span className="bold">M. Demba SOUMARE</span>.
            </p>
            <p className="cgv-section-text">
              Le Site est hébergé sur www.o2switch.fr, géographiquement
              localisée à Clermont-Ferrand (France). Nous contacter :
            </p>
            <ul className="cgv-section-list">
              <li className="cgv-section-list-item">
                <span>Par téléphone</span> : 07 45 65 88 26
              </li>
              <li className="cgv-section-list-item">
                <span>Par email</span> :{" "}
                <a
                  href="mailto:contact@wyze-kids.com"
                  aria-label="Cliquer ici pour contacter le secretariat par email"
                >
                  contact@wyze-kids.com
                </a>
              </li>
              <li className="cgv-section-list-item">
                <span>Par courrier</span> : Wyze Kids, 21 avenue Raspail – 93420
                VILLEPINTE, France
              </li>
            </ul>
            {/* <Strie scale={5} culor="red" left="-20%" top="20%" zindex={-1} />
            <Strie scale={2} culor="red" left="-19%" top="70%" zindex={-1} />
            <Strie scale={6} culor="blue" left="115%" top="50%" zindex={-1} /> */}
          </div>
          <div className="cgv-section" id="cgv-objet">
            <h2 className="cgv-section-title">2. Objet</h2>
            <p className="cgv-section-text">
              Les présentes CGV s’appliquent aux Services proposés par Wyze Kids
              dans le cadre d’une contractualisation formalisant l’achat de
              formation conclu avec{" "}
              <span className="bold"> tout Apprenant </span>
              personne physique.
              <br />
              Les CGV sont opposables à l’Apprenant qui reconnaît en avoir eu
              connaissance avant de valider son inscription et les accepter sans
              réserve.
            </p>
          </div>
          <div className="cgv-section" id="cgv-our-services">
            <h2 className="cgv-section-title">3. Nos services</h2>
            {/* <br /> */}
            <p className="cgv-section-text">
              Wyze Kids propose notamment des Services de formation à distance
              et en présentiel dans des locaux situés en région parisienne,
              ainsi que des prestations accessoires dont Vous bénéficierez et
              qui forment un tout indivisible. Tous les Services proposés par
              Wyze Kids sont décrits et présentés avec la plus grande exactitude
              possible sur le Site.
            </p>
            <br />
            <p className="cgv-section-text">
              Les Services sont tous disponibles en formation individuelle.
            </p>
            <br />
            <p className="cgv-section-text">
              Pour toute question relative à la pédagogie des programmes de
              formation à distance proposés par Wyze Kids, l’Apprenant peut
              appeler le{" "}
              <span className="bold subtitle-color">07 45 65 88 26</span>, du{" "}
              <span className="bold subtitle-color">
                lundi au samedi de 9h00 à 19h.
              </span>
            </p>
            <br />
            <p className="cgv-section-text">
              Les Services comprennent notamment :
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">
                3.1. Des cours{" "}
              </span>{" "}
              <span className="bold">:</span> contenus pédagogiques numériques,
              bibliothèque de cours-vidéos enregistrés ou cours en direct (de
              synthèse, d’approfondissement, de révision).
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">
                3.2. Des auto-évaluations
              </span>{" "}
              <span className="bold">:</span> évaluations et entraînements sous
              forme de quiz et cas pratiques.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">
                3.3. Un accompagnement et un encadrement
              </span>{" "}
              <span className="bold">:</span> assistance assurée par les
              formateurs via le forum ou en live, support par des chargés de
              relation apprenant et des techniciens informatiques, planning
              personnalisé, tableau de bord.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">
                3.4. De l’interaction entre apprenants
              </span>{" "}
              <span className="bold">:</span> via des outils de communication et
              des espaces collaboratifs (en ligne et en présentiel facultatif
              selon les formations).
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">
                3.5. Des mises en pratique
              </span>{" "}
              <span className="bold">:</span> selon les formations, l'Apprenant
              peut bénéficier sur simple demande d’une convention de stage
              (stage obligatoire ou conseillé).
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">
                3.6. Un ou plusieurs logiciel(s) et/ou service(s) tiers en ligne
                et la fourniture de supports écrits, selon les formations.
              </span>
            </p>
          </div>

          <div className="cgv-section" id="cgv-inscription-acces-services">
            <h2 className="cgv-section-title">4. Inscriptions</h2>
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">
                4.1. La demande d’inscription de l’Apprenant
              </span>{" "}
              est prise en compte à la date fixée sur le Contrat, sous réserve :
              <br />
              &nbsp;&nbsp;&nbsp;
              <span className="bold subtitle-color">4.1.1</span> De réception de
              toutes les pièces constitutives du dossier dûment complétées et
              signées.
              <br />
            </p>
            <br />
          </div>

          <div className="cgv-section" id="cgv-program-duration">
            <h2 className="cgv-section-title">5. Durée de la formation</h2>
            <p className="cgv-section-text">
              L’inscription de l’Apprenant et le démarrage de la formation sont
              effectifs conformément aux conditions mentionnées au point 4.1.
              des CGV.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">
                5.1. La période initiale
              </span>{" "}
              se termine à la date choisie au démarrage de la formation, en
              concertation, le cas échéant, avec le Conseiller en Formation, en
              prenant en compte le volume horaire de formation prévu et
              l'intensité hebdomadaire à réaliser par l’Apprenant. Cette date
              figure au Contrat.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">
                5.2. L’échéance de la formation
              </span>{" "}
              est définie par le type de formation suivie et selon les
              conditions figurant dans le Contrat.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">
                5.3. La fin du Contrat
              </span>{" "}
              coïncide avec la date de fin de formation choisie par l’Apprenant
              au moment de son inscription.
            </p>
          </div>

          <div className="cgv-section" id="cgv-fees-and-payment-conditions">
            <h2 className="cgv-section-title">
              6. Frais de services et conditions de règlement
            </h2>
            <span className="bold underline subtitle-color">
              6.1. Frais de Services
            </span>{" "}
            <p className="cgv-section-text">
              Les prix des Services sont indiqués en euros et toutes taxes
              comprises.
              <br />
              Les frais bancaires occasionnés par le mode de paiement choisi par
              l’Apprenant seront à sa charge.
            </p>
            <br />
            <span className="bold underline subtitle-color">
              6.2. Conditions de règlement
            </span>{" "}
            <p className="cgv-section-text">
              Le paiement des produits s'effectue en ligne via la solution de
              paiement Klarna en 3 fois sans frais.
              <br />
              <br />
              Le paiement en 3 fois sans frais est soumis à l'acceptation de
              Klarna. En cas de refus de Klarna, le client devra choisir de
              payer par virement.
              <br />
              <br />
              Le montant total de la commande sera divisé en trois paiements
              égaux, le premier paiement étant effectué au moment de la
              commande, et les deux autres paiements étant prélevés
              automatiquement sur la carte bancaire du client aux dates
              indiquées lors de la commande.
              <br />
              <br />
              En cas de non-paiement d'une échéance, Klarna se réserve le droit
              de facturer des frais de retard et de recouvrer les sommes dues
              par tous moyens légaux.
              <br />
              Les frais bancaires occasionnés par le mode de paiement choisi par
              l’Apprenant seront à sa charge ou pris en charge par son financeur
              le cas échéant.
            </p>
            <br />
            <span className="bold underline subtitle-color">
              6.3. En cas d’impayés ou incidents de paiement{" "}
            </span>{" "}
            <br />
            <br />
            <p className="cgv-section-text">
              À la suite d’un incident de paiement, l’Apprenant doit
              impérativement contacter Wyze Kids par téléphone (07 45 62 88 26)
              ou par email (
              <a
                href="mailto:contact@wyze-kids.com"
                aria-label="Cliquer ici pour nous contacter par email"
              >
                <span className="bold">contact@wyze-kids.com</span>
              </a>
              ) le service administratif de l’École. Il doit obligatoirement
              adresser, dans un délai de 10 jours calendaires maximum, un
              règlement d’un montant égal au versement non perçu.
              <br />
              <br />
              En outre, à la constatation d’un incident de paiement non
              régularisé, l’École est en droit :
              <br />
              <br />
              &nbsp; - &nbsp; de réclamer le règlement intégral des mensualités
              restantes
              <br />
            </p>
          </div>

          <div className="cgv-section" id="cgv-retraction-rights">
            <h2 className="cgv-section-title">7. Droit de rétractation</h2>
            <p className="cgv-section-text">
              Dans le cadre d’un contrat conclu à distance, l’Apprenant dispose
              d’un délai de 14 jours francs, après le jour de la conclusion du
              Contrat, pour exercer son droit de rétractation sans avoir à
              justifier de motifs ni à payer de pénalités, à l’exception, le cas
              échéant, des frais de retour. Si le délai de quatorze jours vient
              à expirer un samedi, un dimanche ou un jour férié ou chômé, il est
              prolongé jusqu’au premier jour ouvrable suivant.
            </p>
            <br />
            <p className="cgv-section-text">
              La demande de rétractation peut se faire par courrier en lettre
              simple auprès de Wyze Kids – 21 avenue Raspail – 93420 VILLEPINTE
              ou par email{" "}
              <span className="bold">
                <a
                  href="mailto:resiliation@wyze-kids.com"
                  aria-label="Cliquer ici pour nous contacter par email"
                >
                  <span className="bold">resiliation@wyze-kids.com</span>
                </a>
              </span>{" "}
              sur papier libre ou via le{" "}
              <span className="bold">
                <a
                  href={retractionForm}
                  download="formulaire de rétractation.pdf"
                  aria-label="Télécharger le formulaire de rétractation"
                >
                  formulaire de rétractation
                </a>
              </span>{" "}
              disponible sur le site internet de l’École et en annexe aux CGV.
              La date de réception de la notification détermine la date de
              rétractation de la formation.
            </p>

            <p className="cgv-section-text">
              En cas d’exercice du droit de rétractation, l’École rembourse
              l’Apprenant de la totalité des sommes qu’il a déjà versées, dans
              les meilleurs délais et au plus tard dans les quatorze jours
              suivant la date à laquelle ce droit a été exercé.
            </p>
          </div>

          <div className="cgv-section" id="cgv-cancellation">
            <h2 className="cgv-section-title">8. Résiliation</h2>
            <span className="bold underline subtitle-color">
              8.1. Résiliation pour cas fortuit ou force majeure (article L.
              444-8 du Code de l’éducation)
            </span>{" "}
            <p className="cgv-section-text">
              Conformément à l’article L.444-8 du Code de l’Éducation reproduit
              en annexe des CGV, le Contrat peut être résilié par l’Apprenant ou
              son représentant légal, si, par suite d’un cas fortuit ou d’une
              force majeure avéré et justifié, il est empêché de suivre la
              formation correspondante. Dans ce cas, la résiliation ne donne
              lieu à aucune indemnité.
            </p>
            <br />
            <span className="bold underline subtitle-color">
              8.2. Résiliation unilatérale dans un délai de 3 mois (article L.
              444-8 du Code de l’éducation)
            </span>{" "}
            <p className="cgv-section-text">
              Jusqu’à l’expiration d’un délai de 3 mois à compter de la date
              d’entrée en vigueur du Contrat, l’Apprenant peut résilier
              unilatéralement le Contrat sans motif.
            </p>
            <p className="cgv-section-text">
              Toute résiliation émanant de l’Apprenant dans ce délai entraînera
              l’application des frais de résiliation suivants :
            </p>
            <br />
            &nbsp; - &nbsp; Frais de formation : Les sommes déjà versées ne
            seront pas remboursées
            <br />
            <br />
            <span className="bold underline subtitle-color">
              8.3. Modalités de résiliation
            </span>{" "}
            <p className="cgv-section-text">
              Dans les hypothèses de résiliation prévues à l’article 8.1 et 8.2
              des CGV, la demande unilatérale de résiliation doit être notifiée
              à l’École par lettre recommandée avec accusé de réception, en
              précisant le motif s’agissant d’une résiliation dans le cadre de
              l’article 8.1 des CGV. La date de réception de la notification
              détermine la date de résiliation du Service. L’École adressera
              sous 15 jours le décompte des frais de résiliation applicables le
              cas échéant et restitue, s’il y a lieu, les sommes versées par
              l’Apprenant qui ne constituent pas la contrepartie des Services
              effectivement rendus à la date de résiliation.
            </p>
            <br />
            <span className="bold underline subtitle-color">
              8.4. Absence
            </span>{" "}
            <p className="cgv-section-text">
              Toute demande de résiliation ne relevant pas de l’article 8.1 ou
              8.2 des CGV ne sera pas acceptée par l'École, les frais de
              Services seront maintenus conformément au Contrat.
            </p>
            <br />
            <Strie scale={1.5} culor="blue" left="-20%" top="0" zindex={-1} />
            <Strie scale={3} culor="blue" left="-15%" top="50%" zindex={-1} />
            <Strie scale={5} culor="red" left="100%" top="0%" zindex={-1} />
            <Strie scale={3} culor="red" left="110%" top="10%" zindex={-1} />
          </div>

          <div className="cgv-section" id="cgv-force-majeure">
            <h2 className="cgv-section-title">9. Force majeure</h2>
            <p className="cgv-section-text">
              Aucune des deux Parties ne sera tenue pour responsable vis-à-vis
              de l'autre de la non-exécution ou des retards dans l'exécution
              d'une obligation du Contrat qui seraient dus au fait de l'autre
              partie consécutivement à la survenance d'un cas de force majeure
              tel que défini par l’article 1218 du Code Civil.
            </p>
            <br />
            <p className="cgv-section-text">
              Le cas de force majeure suspend les obligations nées du Contrat
              pendant toute la durée de son existence.
            </p>
            <br />
            <p className="cgv-section-text">
              En cas de survenance d’un cas de force majeure, l’Apprenant ou
              l’École pourront notifier à l’autre Partie la résiliation du
              Contrat par lettre recommandée avec avis de réception en précisant
              les motifs de cette résiliation et apportant la preuve de la cause
              de force majeure. A défaut de réponse, la résiliation prendra
              effet dans un délai de huit jours après réception de la lettre.
            </p>
            <br />
            <p className="cgv-section-text">
              Si la résiliation du Contrat intervient dans le cadre de la force
              majeure, les règles d’indemnisation de l’article 8.1 des CGV
              trouveront également à s’appliquer.
            </p>
            {/* <br /> */}
          </div>

          <div className="cgv-section" id="cgv-apprentice-duties">
            <h2 className="cgv-section-title">10. Responsabilité</h2>
            {/* <br /> */}
            <span className="bold underline subtitle-color">
              10.1. Responsabilité de l’Apprenant
            </span>{" "}
            <p className="cgv-section-text">
              L’Apprenant est seul responsable du choix des services de
              formation à distance proposés par l’École, de leur conservation et
              de leur utilisation dans le cadre du respect de la Politique de
              protection des données personnelles, du Règlement intérieur et des
              Conditions Générales d’Utilisation de la Plateforme digitale.
              L’Apprenant déclare connaître et accepter les caractéristiques et
              les limites de la transmissiond’informations par le réseau
              internet, ainsi que les coûts propres à la connexion à ce réseau.
              Il reconnaît qu’il lui appartient de s’assurer que les
              caractéristiques techniques du matériel qu’il utilise lui
              permettent un accès aux services de formation à distance dans de
              bonnes conditions, en particulier en ce qui concerne la
              transmission de documents par internet, et de prendre toutes
              mesures appropriées pour être protégé d’une contamination par
              d’éventuels programmes contenant un virus.
            </p>
            <br />
            <span className="bold underline subtitle-color">
              10.2. Responsabilité de l’École
            </span>{" "}
            <p className="cgv-section-text">
              - &nbsp; A l’égard de l’Apprenant, la responsabilité de l’École
              peut être engagée en cas de manquement à l’une de ses obligations.
              <br />
            </p>
          </div>
          <div className="cgv-section" id="cgv-intellectual-property">
            <h2 className="cgv-section-title">11. Propriété intellectuelle</h2>
            {/* <br /> */}

            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">11.1. </span>
              &nbsp; Le contenu des Services est la propriété de l’École et
              celle de ses partenaires. Il est comme tel strictement réservé au
              titre du droit d'auteur ainsi qu'au titre de la propriété
              intellectuelle par leurs titulaires et pour le monde entier. En
              conséquence, toute reproduction, imitation ou représentation
              totale ou partielle non autorisée des marques, logos, signes
              distinctifs et contenus des Services constitue une violation du
              droit d'auteur passible de poursuites civiles et pénales.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">11.2.</span>&nbsp;
              Les supports de formation, qu'ils soient imprimés ou numériques,
              sont réservés aux seules fins de l'utilisation par l’Apprenant
              même. La diffusion, la revente et la location des supports
              imprimés ou numériques, quelle que soit leur présentation,
              constitue une violation déterminante des CGV et pourra justifier
              de l’exclusion définitive de l’Apprenant et d'un recours en
              justice de la part de l'École.
            </p>
            <br />
            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">11.3.</span>&nbsp;
              Toute mise à disposition à des fins commerciales non autorisées
              des supports de formation (cours, exercices, vidéos, logiciels) de
              l'École, protégés par des droits d'auteur, sur un site internet
              personnel ou commercial, ou sur un quelconque support numérique ou
              imprimé est strictement interdit et aboutira à l’exclusion de
              l’Apprenant de notre établissement. Des poursuites judiciaires
              seront alors engagées par l'École.
            </p>
            {/* <br /> */}
          </div>

          <div
            className="cgv-section"
            id="cgv-confidentiality-and-personal-data"
          >
            <h2 className="cgv-section-title">
              12. Confidentialité et protection des données à caractère
              personnel{" "}
            </h2>
            {/* <br /> */}

            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">12.1. </span>
              &nbsp; Wyze Kids s'engage à garder strictement confidentiels et à
              ne pas divulguer ou laisser divulguer ou communiquer à quiconque,
              par quelque moyen que ce soit, les documents, données, prototypes,
              informations, outils, logiciels (ci-après désignés globalement «
              les Informations ») transmis par l’Apprenant ou dont il aurait eu
              connaissance à l'occasion de ses relations avec l’Apprenant.
            </p>
            <br />

            <p className="cgv-section-text">
              <span className="bold underline subtitle-color">12.2. </span>
              &nbsp; Pour les besoins de la présente clause on entend par «
              Données Personnelles » les données à caractère personnel telles
              que définies par le règlement (UE) 2016/679 du Parlement Européen
              et du Conseil du 27 avril 2016 (« RGPD »).
            </p>
            <p className="cgv-section-text">
              Wyze Kids reconnaît que les Données Personnelles des Apprenants et
              leurs traitements y afférents sont soumis aux dispositions légales
              et réglementaires de protection des données à caractère personnel
              applicables dont notamment le règlement (UE) 2016/679 du Parlement
              Européen et du Conseil du 27 avril 2016, applicable à compter du
              25 mai 2018 et toutes réglementations locales prises en
              application ou complément de ce dernier (ci-après ensemble le «
              RGPD ») et chacune d’elles s’engage à respecter le RGPD.
            </p>
            <br />
            <p className="cgv-section-text">
              A ce titre Wyze Kids s’engage à :
            </p>
            <br />
            <p className="cgv-section-text">
              - &nbsp; Traiter les données uniquement pour la ou les seule(s)
              finalité(s) qui fait / font l’objet de l’exécution du Contrat ou
              la Convention de formation professionnelle et l’exécution de ses
              obligations légales et réglementaires
            </p>
            <p className="cgv-section-text">
              - &nbsp; Assurer la sécurité des données personnelles et mettre en
              place les mesures de sécurité appropriées
            </p>
            <p className="cgv-section-text">
              - &nbsp; Gérer la rétention des données conformément au
              référentiel des délais de conservation
            </p>
            <p className="cgv-section-text">
              - &nbsp; Wyze Kids assure à l’Apprenant droit d’accès et de
              rectification, de suppressions, d’opposition et de portabilité
            </p>
            <p className="cgv-section-text">
              Pour toute question relative à la présente section de protection
              des données personnelles ou pour toute demande relative à vos
              données personnelles, vous pouvez nous contacter en adressant un
              e-mail à l’adresse électronique{" "}
              <a
                href="mailto:contact@wyze-kids.com"
                aria-label="Cliquer ici pour nous contacter sur le sujet de protection de vos données personnelles"
              >
                <span className="bold underline">contact@wyze-kids.com</span>{" "}
              </a>
            </p>
            <br />
            <p className="cgv-section-text">
              Si vous souhaitez de plus amples informations sur la protection
              des données personnelles ou introduire une réclamation :
            </p>
            <p className="ms-2">
              Commission nationale de l’informatique et des libertés (CNIL)
            </p>
            <p className="ms-2">
              3 place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07
            </p>
            <p className="ms-2">Téléphone : +33 (0)1 53 73 22 22</p>
            <p className="ms-2">
              <a
                href="https://www.cnil.fr/fr/adresser-une-plainte"
                aria-label="Adresser une plainte via le site de la cnil"
              >
                <span className="bold underline">
                  https://www.cnil.fr/fr/adresser-une-plainte
                </span>
              </a>
            </p>
            <Strie scale={1.5} culor="blue" left="-20%" top="0" zindex={-1} />
            <Strie scale={3} culor="blue" left="-15%" top="50%" zindex={-1} />
            <Strie scale={5} culor="red" left="100%" top="0%" zindex={-1} />
            <Strie scale={3} culor="red" left="110%" top="10%" zindex={-1} />
          </div>

          <div
            className="cgv-section"
            id="cgv-applicable-rights-and-dispute-settlement"
          >
            <h2 className="cgv-section-title">
              13. Droit applicable et règlement des litiges
            </h2>
            {/* <br /> */}
            <p className="cgv-section-text">
              Le Contrat sera exécuté et interprété conformément au droit
              français.
              <br />
              Avant tout contentieux, les parties chercheront à s’entendre au
              travers d’un éventuel accord amiable.
              <br />
              Lorsque l’Apprenant a adressé une réclamation écrite à l'École, et
              que les suites données à celle-ci ne lui ont pas donné
              satisfaction, il peut, conformément à l'article L. 612-1 du Code
              de la consommation, introduire gratuitement une demande de
              résolution amiable par voie de médiation. Le médiateur doit être
              saisi dans le délai maximal d’un an à compter de la réclamation
              initiale auprès de Wyze Kids.
            </p>
            <br />
            <p className="cgv-section-text">
              Le médiateur compétent peut être saisi directement en ligne aux
              coordonnées suivantes :{" "}
              <a
                href="https://cm2c.net/"
                aria-label="Saisir un médiateur en ligne sur le site de CM2C"
              >
                <span className="bold underline">https://cm2c.net/</span>
              </a>{" "}
              ou CM2C, à l’adresse 49 rue de Ponthieu 75008 PARIS.
            </p>
            <p className="cgv-section-text">
              L’Apprenant a également la possibilité de s’adresser à la
              plateforme européenne de règlement des litiges en ligne disponible
              via le lien suivant :
              <br />
              <a
                href="https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=FR."
                aria-label="S'adresser à la plateforme européenne de règlement des litiges en ligne"
              >
                <span className="bold underline">
                  https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=FR.
                </span>
              </a>
            </p>
            <p className="cgv-section-text">
              Cette plateforme a pour but de proposer au consommateur des
              procédures de règlement amiable de litiges offertes par des
              entités qualifiées pour traiter des litiges entre consommateurs et
              entreprises ou professions libérales situés dans l’Union
              européenne.
            </p>
            <br />
            <p className="cgv-section-text">
              A défaut de règlement amiable, le litige sera porté devant les
              juridictions compétentes et, si la loi le permet, devant les
              tribunaux du ressort de Bobigny.
            </p>
            <br />
            <p className="cgv-section-text">
              Toute demande d’information ou réclamation relative aux CGV doit
              être adressée par email à{" "}
              <a
                href="mailto:direction@wyze-kids.com"
                aria-label="Nous contacter pour toute demande d'informations relatives aux CGV."
              >
                <span className="bold underline">direction@wyze-kids.com.</span>
              </a>
            </p>
            <br />
            <p className="cgv-section-text">
              Dans le cas où une clause contractuelle serait touchée de nullité,
              celle-ci n’entraîne pas la nullité des CGV.
            </p>
            <br />
            <p className="cgv-section-text">
              D’autre part, l’inapplication temporaire ou permanente d’une ou
              plusieurs clauses des CGV par l’École ne saurait valoir
              renonciation de sa part aux autres clauses des CGV qui continuent
              à produire leurs effets.
            </p>
            <br />
            <p className="cgv-section-text">
              Pour les cours dont la durée totale est supérieure à trois mois,
              les 30 % sont calculés sur le prix de la première année
              pédagogique telle qu’elle est prévue par le plan d’études.
            </p>
            <br />
            <p className="cgv-section-text">
              Le contrat doit, à peine de nullité, reproduire les dispositions
              du présent article. Il ne peut comporter de clause attributive de
              compétences. »
            </p>
          </div>

          <div className="cgv-section" id="cgv-appendix-1/1">
            <h2 className="cgv-section-title">ANNEXE 1/1</h2>
            <p className="cgv-section-text bold subtitle-color">
              Article L444-8 du Code de l’Éducation
            </p>
            <p className="cgv-section-text bold subtitle-color">
              Version en vigueur depuis le 22 juin 2000
            </p>
            <br />
            <p className="cgv-section-text">
              A peine de nullité, le contrat ne peut être signé qu'au terme d'un
              délai de sept jours après sa réception.
            </p>
            <br />
            <p className="cgv-section-text">
              Le contrat peut être résilié par l'élève, ou son représentant
              légal, si, par suite d'un cas fortuit ou d'une force majeure, il
              est empêché de suivre l'enseignement correspondant. Dans ce cas,
              la résiliation ne donne lieu à aucune indemnité.
            </p>
            <br />
            <p className="cgv-section-text">
              Jusqu'à l'expiration d'un délai de trois mois à compter de la date
              d'entrée en vigueur du contrat, celui-ci peut être unilatéralement
              résilié par l'élève moyennant une indemnité dont le montant ne
              saurait excéder 30 % du prix du contrat, fournitures non
              comprises. Les sommes déjà versées peuvent être retenues à due
              concurrence.
            </p>
            <br />
            <p className="cgv-section-text">
              Les livres, objets ou matériels dont le contrat prévoyait la
              fourniture à l'élève et qui ont été effectivement livrés à la date
              de la résiliation, restent acquis pour la valeur estimée au
              contrat.
            </p>
            <br />
            <p className="cgv-section-text">
              Il ne peut être payé par anticipation plus de 30 % du prix
              convenu, fournitures non comprises.
            </p>
            <br />
            <p className="cgv-section-text">
              Le contrat doit, à peine de nullité, reproduire les dispositions
              du présent article. Il ne peut comporter de clause attributive de
              compétence.
            </p>
            <br />
            <Strie scale={5} culor="red" left="-20%" top="20%" zindex={-1} />
            <Strie scale={2} culor="red" left="-19%" top="140%" zindex={-1} />
            <Strie scale={6} culor="blue" left="115%" top="40%" zindex={-1} />
          </div>

          <div className="cgv-footer">
            <span>
              <p>07 45 62 88 26</p>
              <p>
                <a
                  href="mailto:secretariat@wyze-kids.com"
                  aria-label="Contacter le secretariat via email"
                >
                  <span className="bold unerline">
                    secretariat@wyze-kids.com
                  </span>
                </a>
              </p>
              <p>Siège social : 21 avenue Raspail 93420 VILLEPINTE </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
