import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
  FaTiktok,
  FaWhatsapp,
} from "react-icons/fa";
import { BiHandicap } from "react-icons/bi";
import "./Footer.css";
import { Link } from "react-router-dom";
import logo from "../../media/images/wyze-kids-logo.png";
import MetroIcon from "../../media/icons/m.png";
import Metro13Icon from "../../media/icons/13.png";
import Metro14Icon from "../../media/icons/14.png";
import BusIcon from "../../media/icons/bus.png";
import Bus137Icon from "../../media/icons/137.png";
import Bus341Icon from "../../media/icons/341.png";
import RerIcon from "../../media/icons/rerIcon.png";
import RerCIcon from "../../media/icons/rerC.png";
import TramIcon from "../../media/icons/TramIcon.png";
import T3bIcon from "../../media/icons/T3b.png";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-top1"></div>
      <div className="footer-top2"></div>
      <div className="footer-container">
        <div className="footer-body">
          <div className="social-networks-container">
            <h4 className="social-networks-title semi-bold">Suivez-nous</h4>
            <div className="social-networks-icons-container">
              <a
                href="https://web.facebook.com/profile.php?id=61561282500074"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Lien vers notre page Facebook"
              >
                <FaFacebook
                  style={{
                    cursor: "pointer",
                    width: "25px",
                    height: "100%",
                    color: "white",
                  }}
                />
              </a>
              <a
                href="https://www.instagram.com/wyze.kids/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Lien vers notre page Instagram"
              >
                <FaInstagram
                  style={{
                    cursor: "pointer",
                    width: "25px",
                    height: "100%",
                    color: "white",
                  }}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/academy-wyze/?viewAsMember=true"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Lien vers notre page Linkedin"
              >
                <FaLinkedin
                  style={{
                    cursor: "pointer",
                    width: "25px",
                    height: "100%",
                    color: "white",
                  }}
                />
              </a>
              <a
                href="https://www.youtube.com/@WyzeAcademy"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Lien vers notre chaine Youtube"
              >
                <FaYoutube
                  style={{
                    cursor: "pointer",
                    width: "25px",
                    height: "100%",
                    color: "white",
                  }}
                />
              </a>
              <a
                href="https://www.tiktok.com/@wyzekids"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Lien vers notre compte Tiktok"
              >
                <FaTiktok
                  style={{
                    cursor: "pointer",
                    width: "25px",
                    height: "100%",
                    color: "white",
                  }}
                />
              </a>
              <a
                href="https://wa.me/0745628826"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Lien pour nous contacter via Whatsapp"
              >
                <FaWhatsapp
                  style={{
                    cursor: "pointer",
                    width: "25px",
                    height: "100%",
                    color: "white",
                  }}
                />
              </a>
            </div>
          </div>

          <div className="plan-container">
            <h4 className="plan-title semi-bold">Plan du site</h4>
            <div className="plan-body">
              <div className="plan-column">
                <a
                  href="/construction-progress?title=Kids"
                  className="plan-link"
                  aria-label="Lien vers la page avec les formations pour les enfants"
                >
                  <p className="plan-text">Kids</p>
                </a>
              </div>
              <div className="plan-column">
                <a
                  href="/construction-progress?title=Ados"
                  className="plan-link"
                  aria-label="Lien vers la page avec les formations pour les adolescents"
                >
                  <p className="plan-text">Adolescents</p>
                </a>
              </div>
            </div>
          </div>

          <div className="info-container">
            <h4 className="info-title semi-bold">Informations pratiques</h4>
            <div className="info-body">
              <Link
                to="/mentions-legales"
                className="info-link"
                aria-label="Lien vers la page des mentions légales"
              >
                <p className="info-text">Mentions légales</p>
              </Link>

              <Link
                to="/mentions-legales/accessibilite"
                className="info-link"
                id="footer-accessibility-link"
                aria-label="Lien vers la page d'accessibilité"
              >
                <div className="accessibility-link-container">
                  <BiHandicap
                    style={{
                      cursor: "pointer",
                      width: "25px",
                      height: "100%",
                      color: "white",
                    }}
                  />
                  <p className="info-text">Accessibilité</p>
                </div>
              </Link>

              {/* <Link
                to="/#contact-section"
                className="info-link"
                id="contact-section-footer-link">
                </Link> */}
              <a
                href="/#contact-section"
                className="info-link"
                rel="noopener noreferrer"
              >
                <p className="info-text">Nous contacter</p>
              </a>

              {/* <a
                href="https://g.co/kgs/g28ddXF"
                target="_blank"
                rel="noopener noreferrer"
                className="info-link"
              >
                <p className="info-text">Nous retrouver</p>
              </a> */}
            </div>
            <p className="footer-text footer-adress-responsive">
              Adresse : 60 Av. du Capitaine Glarner, 93400 Saint-Ouen-sur-Seine
            </p>
          </div>

          <div className="transport-container">
            <h4 className="transport-title semi-bold">Transports</h4>
            <div className="transport-body">
              <div className="transport-section">
                <div className="transport-description">
                  <img
                    src={MetroIcon}
                    // width={"25px"}
                    className="footer-transport-icon"
                  />
                  <img
                    src={Metro13Icon}
                    // width={"30px"}
                    className="footer-transport-icon"
                  />
                  <p className="transport-text">Garibaldi</p>
                </div>
                <div className="transport-description">
                  <img
                    src={MetroIcon}
                    // width={"25px"}
                    className="footer-transport-icon"
                  />
                  <img
                    src={Metro14Icon}
                    // width={"30px"}
                    className="footer-transport-icon"
                  />
                  <p className="transport-text">Saint-Ouen</p>
                </div>
                <div className="transport-description">
                  <img
                    src={BusIcon}
                    className="footer-transport-icon"
                    // style={{ maxWidth: "25px", objectFit: "contain" }}
                  />
                  <img
                    src={Bus341Icon}
                    className="footer-transport-icon"
                    // style={{ maxWidth: "30px", objectFit: "contain" }}
                  />
                  <p className="transport-text">Toulouse Lautrec</p>
                </div>
                <div className="transport-description">
                  <img
                    src={RerIcon}
                    // width={"25px"}
                    className="footer-transport-icon"
                  />
                  <img
                    src={RerCIcon}
                    // width={"25px"}
                    className="footer-transport-icon"
                  />

                  <p className="transport-text">Saint-Ouen</p>
                </div>
                <div className="transport-description">
                  <img
                    src={TramIcon}
                    // width={"25px"}
                    className="footer-transport-icon"
                  />

                  <img
                    src={T3bIcon}
                    // width={"30px"}
                    className="footer-transport-icon"
                  />
                  <p className="transport-text">Porte de Saint-Ouen</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-footer">
          <a href={"/#"}>
            <img src={logo} alt="Logo Wyze" className="logo-footer-image" />
          </a>
          <p className="footer-text footer-adress">
            Adresse : 60 Av. du Capitaine Glarner, 93400 Saint-Ouen-sur-Seine
          </p>
          <p className="footer-text">© 2024 Wyze Academy</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
