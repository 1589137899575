import "./AtelierCard.css";
import label from "../../../../../media/images/label.png";
import Contact from "../../../../../components/Contact/Contact";
import React, { useRef } from "react";

import KidsFormationInfoDetails from "./KidsFormationInfoDetails";

import GridComponents from "./GridComponent/GridComponent";

function AtelierCard({ img, title, desc, formation, ticket }) {
  const moreDialogRef = useRef();
  const moreScrollableRef = useRef();

  const contactDialogRef = useRef();
  const contactScrollableRef = useRef();

  const openModal = (ref, scrollRef) => {
    ref.current?.showModal();
    document.body.style.overflow = "hidden";
    scrollRef.current.scrollTo(0, 0);
  };

  const closeModal = (ref) => {
    ref.current?.close();
    document.body.style.overflow = "unset";
  };

  return (
    <div>
      <div className="atelier-card-container-anchor">
        <GridComponents images={img} />
        <span className={`label2 ${ticket ? "hide" : ""}`}>
          <span>
            {formation.formation_age}
            <br /> ans
          </span>
          <img src={label} />
        </span>

        <h3>{formation.formation_title}</h3>
        <span className={`label2 ${ticket ? "" : "hide"}`}>
          <span>
            {formation.formation_age}
            <br /> ans
          </span>
          <img src={label} />
        </span>
        <p>{formation.formation_description}</p>
        <div className="btn-wrapper">
          <button
            className="atelier-card-botton"
            onClick={() => {
              openModal(moreDialogRef, moreScrollableRef);
            }}
          >
            En Savoir Plus
          </button>
        </div>
      </div>
      <dialog className="formation-kids-more-modal" ref={moreDialogRef}>
        <div
          ref={moreScrollableRef}
          className="formation-kids-more-modal-wrapper"
        >
          <KidsFormationInfoDetails formation={formation} />
        </div>
        <button
          className="kids-formation-details-close-button"
          onClick={() => closeModal(moreDialogRef)}
        >
          &#x2715;
        </button>
      </dialog>
      <dialog
        id="dialooog"
        className="formation-kids-contact-modal"
        ref={contactDialogRef}
      >
        <div
          ref={contactScrollableRef}
          className="formation-kids-contact-modal-wrapper"
        >
          <Contact subject={formation.formation_title} />
        </div>
        <button
          className="kids-formation-details-close-button"
          onClick={() => closeModal(contactDialogRef)}
        >
          &#x2715;
        </button>
      </dialog>
    </div>
  );
}

export default AtelierCard;
