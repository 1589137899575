import React from "react";
import "./CguSection.css";
import Strie from "../../../components/Strie/Strie";

function CguPage() {
  return (
    <div className="cgu-wrapper">
      <div className="cgu-container">
        <div className="cgu-header">
          <h1>Conditions générales d'utilisation du site</h1>
        </div>
        <div className="cgu-body">
          <div className="cgu-section" id="cgu-definitions">
            <h2 className="cgu-section-title">Article 1 - Définitions</h2>
            <p className="cgu-section-text">
              <span className="bold subtitle-color">« Utilisateur »</span> :
              toute personne physique ou morale utilisant le Site.&nbsp;
              <span className="bold">
                Les systèmes automatisés ou un logiciel accédant au Site sont
                expressément considérés comme des utilisateurs non légitimes de
                la base de données disponible sur le Site ou n'ayant pas
                licitement accès à la base de données disponible sur le Site.
              </span>
            </p>
            <p className="cgu-section-text">
              <span className="bold subtitle-color">
                « Utilisateur Candidat »
              </span>{" "}
              : tout Utilisateur, personne physique non professionnelle, ayant
              procédé à son Inscription.
            </p>
            <p className="cgu-section-text">
              <span className="bold subtitle-color">« Client »</span> : tout
              Utilisateur, personne physique ou morale agissant dans le cadre de
              son activité professionnelle, ayant procédé à son Inscription et
              souscrivant aux Services payants ou gratuits de la société Wyze
              Kids, y compris la réservation d'un espace de publication de
              Contenu.
            </p>
            <p className="cgu-section-text">
              <span className="bold subtitle-color">« CVthèque »</span> : espace
              Web hébergeant les CV et Profils et accessible via le Site aux
              Clients ayant souscrit au service « CVthèque ».
            </p>
            <p className="cgu-section-text">
              <span className="bold subtitle-color">« Inscription »</span> :
              action permettant à un Utilisateur de pouvoir accéder à certains
              Services, et matérialisée par la communication de son adresse mail
              à Wyze Kids.
            </p>
            <p className="cgu-section-text">
              <span className="bold subtitle-color">« Contenu »</span> : il
              s'agit d'offre de formation.
            </p>
            <p className="cgu-section-text">
              <span className="bold subtitle-color">« Wyze Kids »</span> : la
              SAS Wyze Academy, 21 avenue Raspail à Villepinte (93420)
              immatriculée sous le n° SIRET 98 47 84 64 500017.
            </p>
            <p className="cgu-section-text">
              <span className="bold subtitle-color">« Services »</span> :
              ensemble des prestations proposées par Wyze Kids, disponibles sur
              le Site et listées ci-après.
            </p>
            <p className="cgu-section-text">
              <span className="bold subtitle-color">« Notification »</span> :
              message proposant le Contenu à destination des Utilisateurs par
              tous canaux de communication et notamment les moyens suivants :
              bannière et élément graphique sur le Site, e-mail, SMS,
              notification mobile, notification web.
            </p>
            <p className="cgu-section-text">
              <span className="bold subtitle-color">« Profil »</span> : ensemble
              des données cullectées et enregistrées par Wyze Kids sur un
              Utilisateur. Pour un Utilisateur Candidat le Profil est nominatif.
              A défaut, il est anonyme. Le Profil inclut le « Curriculum Vitae »
              lorsque l'Utilisateur Candidat l'a utilisé sur le Site.
            </p>
            <p className="cgu-section-text">
              <span className="bold subtitle-color">« Site »</span> : le site
              Web accessible via les noms de domaine suivants : &nbsp;
              <a
                className="cgu-section-link"
                href="/"
                aria-label="Cliquer pour être redirigé vers la page d'accueil"
              >
                https://www.wyze-kids.com
              </a>
            </p>
          </div>
          <div className="cgu-section" id="cgu-objet">
            <h2 className="cgu-section-title">Article 2 - Objet</h2>
            <p className="cgu-section-text">
              Les présentes Conditions générales ont pour objet de fixer les
              conditions d'accès et d'utilisation des Services proposés sur le
              Site.
            </p>
            <Strie scale={5} culor="red" left="-20%" top="20%" zindex={-1} />
            <Strie scale={2} culor="red" left="-19%" top="140%" zindex={-1} />
            <Strie scale={6} culor="blue" left="115%" top="40%" zindex={-1} />
          </div>
          <div className="cgu-section" id="cgu-description-services">
            <h2 className="cgu-section-title">
              Article 3 - Description des Services
            </h2>
            <p className="cgu-section-text">
              En cas de modification de la gratuité ou de la nature des Services
              proposés sur le Site, l'Utilisateur Candidat et/ou le Client en
              sera informé dans les meilleurs délais par mail et sera libre de
              souscrire ou non à ces nouveaux Services et/ou de procéder à la
              résiliation de son Inscription aux Services modifiés.
            </p>
            <h3 className="cgu-section-subtitle">
              3.1 Services gratuits accessibles à tout Utilisateur
            </h3>
            <p className="cgu-section-text">
              L'ensemble des Utilisateurs aura accès aux Services suivants :
            </p>
            <ul className="cgu-section-list">
              <li className="cgu-section-list-item">
                Recherche, accès et consultation du Contenu
              </li>
              <li className="cgu-section-list-item">
                Personnalisation du Contenu en fonction du Profil
              </li>
            </ul>
          </div>
          <div className="cgu-section" id="cgu-conditions-acces-site">
            <h2 className="cgu-section-title">
              Article 4 - Conditions d'accès au Site
            </h2>
            <p className="cgu-section-text">
              En accédant au Site, l'Utilisateur reconnait avoir lu, compris et
              accepté les présentes Conditions Générales et informations
              légales. En conséquence,&nbsp;
              <span className="bold">
                l'Utilisateur reconnaît que tout accès au Site le lie
                contractuellement à Wyze Kids selon les termes des présentes
              </span>
              .
            </p>
            <p className="cgu-section-text">
              L'Utilisateur doit s'assurer de la compatibilité de son matériel
              informatique et des logiciels installés pour accéder au Site.
              L'accès aux Services qui lui sont accessibles est à durée
              indéterminée, sauf modification du Site par Wyze Kids.
            </p>
          </div>
          <div className="cgu-section" id="cgu-user-obligations">
            <h2 className="cgu-section-title">
              Article 5 - Obligations de l'Utilisateur
            </h2>
            <p className="cgu-section-text">
              L'Utilisateur ne peut utiliser les Services du Site pour exercer
              des activités prohibées par la loi et les bonnes mœurs. <br />
              <br /> De façon générale, le Site ne peut être utilisé directement
              ou indirectement à des fins commerciales que par Wyze Kids. La
              thématique du Site est la vie professionnelle. Le Site ne peut
              être détourné par quiconque à des fins sans liaison avec celles
              ci-dessus.
            </p>
            <p className="cgu-section-text">
              A son Inscription, l'Utilisateur Candidat ou le Client s'engage à
              n'indiquer ou à ne diffuser sous quelque forme que ce soit que des
              informations ou contenus conformes à la réalité et le concernant
              personnellement. L'Utilisateur Candidat ou le Client ne peut
              publier d'informations à caractère personnel concernant des tiers,
              sans leur accord exprès préalable En tout état de cause, la
              responsabilité de Wyze Kids ne pourra être recherchée à ce titre.
            </p>
            <p className="cgu-section-text">
              L'Utilisateur Candidat ou le Client est seul juge des informations
              qu'il souhaite mettre sur le Site. Il ne doit pas usurper
              l'identité d'un tiers, ni publier de message à caractère
              diffamatoire ou injurieux, contraire aux bonnes mœurs ou encore de
              nature délictuelle.
            </p>
            <p className="cgu-section-text">
              En adhérant et en publiant des informations sur lui, l'Utilisateur
              Candidat ou le Client donne l'autorisation à Wyze Kids, sur le
              Site ou via des partenaires, de reproduire, de représenter et
              d'adapter les informations qui le concernent et notamment
              d'intégrer ces éléments aux différentes pages et rubriques du Site
              et/ou aux supports de ses partenaires.
            </p>
            <Strie scale={1.5} culor="blue" left="-20%" top="0" zindex={-1} />
            <Strie scale={3} culor="blue" left="-15%" top="50%" zindex={-1} />
            <Strie scale={5} culor="red" left="100%" top="0%" zindex={-1} />
            <Strie scale={3} culor="red" left="110%" top="5%" zindex={-1} />
          </div>
          <div className="cgu-section" id="cgu-alert-reporting">
            <h2 className="cgu-section-title">Article 6 - Signalement</h2>
            <p className="cgu-section-text">
              Wyze Kids assure, à titre gratuit ou payant, le stockage de
              contenu publié par les Utilisateurs Candidats ou le Client pour le
              mettre à la disposition des Utilisateurs. Chaque Utilisateur est
              invité à porter à la connaissance de Wyze Kids tout contenu
              susceptible d'être litigieux, aux conditions de fond et de forme
              suivantes :
            </p>
            <ul className="cgu-section-list">
              <li className="cgu-section-list-item">
                <p className="cgu-section-text">Notification obligatoire </p>
                <p className="cgu-section-text">
                  L'Utilisateur doit porter à la connaissance de Wyze Kids, à
                  l'adresse &nbsp;
                  <a
                    className="cgu-section-link"
                    href="mailto:contact@wyze-kids.com"
                    aria-label="Cliquer pour nous envoyer un mail"
                  >
                    <span className="bold underline">
                      contact@wyze-kids.com
                    </span>
                  </a>
                  , tout message faisant l'apologie des crimes contre
                  l'humanité, incitant à la haine raciale, touchant à la
                  pornographie infantile, incitant à la violence ou portant
                  atteinte à la dignité humaine.
                </p>
              </li>
              <li className="cgu-section-list-item">
                <p className="cgu-section-text">
                  Contenu susceptible d'être illicite
                </p>
                <p className="cgu-section-text">
                  L'Utilisateur qui estimerait qu'un message ou du contenu sur
                  le Site est susceptible d'être inexact, voire illicite, ayant
                  un caractère injurieux, discriminatoire ou diffamatoire, est
                  invité à déposer une notification.
                </p>
              </li>
            </ul>
            <p className="cgu-section-text">
              La notification prend la forme d'un courriel adressé à l'adresse
              contact@wyze-kids.com ou par une lettre recommandée avec accusé de
              réception dans lequel l'Utilisateur indiquera ses noms, prénoms
              (pour une personne morale, sa dénomination sociale et numéro RCS)
              et adresse.
            </p>
            <p className="cgu-section-text">
              La notification doit comprendre la description des faits litigieux
              et leur localisation précise dans le Site, les motifs pour
              lesquels le contenu doit être retiré, comprenant la mention des
              dispositions légales et des justifications de faits. Dans la
              mesure du possible, l'Utilisateur doit transmettre la copie de la
              correspondance adressée à l'auteur des informations ou activités
              litigieuses demandant leur interruption, leur retrait ou leur
              modification, ou la justification de ce que l'auteur ou l'éditeur
              n'a pu être contacté.
            </p>
            <p className="cgu-section-text">
              L'attention de l'Utilisateur auteur d'une notification est attirée
              sur le fait qu'une dénonciation inexacte est susceptible de
              l'exposer à des sanctions pénales et accepte de garantir le Site
              contre les conséquences éventuelles d'une notification abusive.
            </p>
            <p className="cgu-section-text">
              Wyze Kids s'engage à réagir promptement à toute notification d'un
              Utilisateur faite dans les formes et manifestement fondée ou
              révélant des faits constituant à l'évidence une violation d'une
              règle de droit. Wyze Kids veillera à préserver le cas échéant
              l'anonymat de l'auteur des propos litigieux, tout en se réservant
              la faculté de prendre contre lui toute mesure utile, à commencer
              par son exclusion du Site.
            </p>
          </div>
          <div className="cgu-section" id="cgu-website-intelectual-property">
            <h2 className="cgu-section-title">
              Article 7 - Eléments distinctifs du Site - Propriété
              intellectuelle
            </h2>
            <p className="cgu-section-text">
              <span className="bold subtitle-color">7.1</span> La charte
              graphique du Site, ainsi que toutes les marques, logos,
              contributions, photographies, données, textes, commentaires,
              illustrations, images animées ou non, séquences vidéo, sons,
              brevets, données privées, technologies, produits, publications,
              process ainsi que toutes les applications informatiques qui
              pourraient être utilisées pour faire fonctionner le Site et plus
              généralement tous les éléments reproduits ou utilisés sur le Site
              sont protégés au titre de la propriété intellectuelle par les lois
              en vigueur.
            </p>
            <p className="cgu-section-text">
              Ils sont la propriété pleine et entière de Wyze Kids ou de ses
              partenaires. Toute reproduction, représentation, utilisation ou
              adaptation, sous quelque forme que ce soit, de tout ou partie de
              ces éléments, y compris les applications informatiques, sans
              l'accord préalable et écrit de Wyze Kids ou du titulaire des
              droits, sont strictement interdites, à l'exception du
              téléchargement, de l'affichage et de l'impression des données de
              ce site à des fins exclusivement privées et non commerciales.
            </p>
            <p className="cgu-section-text">
              <span className="bold subtitle-color">7.2</span>&nbsp;
              L'Utilisateur peut uniquement utiliser le Site aux fins d'accéder
              aux Services, ce exclusivement à des fins privées et non
              commerciales.
            </p>
            <p className="cgu-section-text bold">
              Il est notamment expressément reconnu et accepté par l'Utilisateur
              que l'utilisation de systèmes automatisés ou de logiciels pour
              extraire des données du Site, en particulier le Contenu et la
              CVthèque (« capture de données d'écran » / « screen scraping / web
              scraping» notamment), à quelques fins que ce soit, commerciales ou
              non, est strictement interdite, à moins que Wyze Kids n'ait conclu
              une convention de licence écrite autorisant expressément
              l'Utilisateur à extraire une partie des données du Site.
            </p>
            <p className="cgu-section-text">
              <span className="bold subtitle-color">7.3</span> Dans l'hypothèse
              d'une protection de la base de données du Site par les articles L.
              342-1 et suivants du Code de la propriété intellectuelle
              (protection au titre du droit sui generis), il est en tout état de
              cause fait interdiction à tout Utilisateur :
            </p>
            <ul className="cgu-section-list">
              <li className="cgu-section-list-item">
                d'extraire par transfert permanent ou temporaire la totalité ou
                une partie qualitativement ou quantitativement substantielle du
                contenu de la CVthèque et/ou du Contenu accessibles sur le Site
                sur un autre support, par tout moyen et sous toute forme que ce
                soit.
              </li>
              <li className="cgu-section-list-item">
                de réutiliser, par la mise à la disposition du public, de la
                totalité ou d'une partie qualitativement ou quantitativement
                substantielle du contenu de la CVthèque et/ou du Contenu, quelle
                qu'en soit la forme.
              </li>
              <li className="cgu-section-list-item">
                d'extraire ou de réutiliser à répétition ou de manière
                systématique des parties qualitativement ou quantitativement non
                substantielles du contenu de la CVthèque et/ou du Contenu
                lorsque ces opérations excèdent manifestement les conditions
                d'utilisation normales de la base de données du Site.
              </li>
              <li className="cgu-section-list-item">
                d'exploiter commercialement auprès de tiers les droits d'accès
                ou de publications du Contenu, le Contenu lui-même.
              </li>
            </ul>
            <span className="bold subtitle-color">7.4 </span> Wyze Kids se
            réserve la possibilité de résilier de plein droit les présentes et
            d'engager toute action pour faire sanctionner toute atteinte à ses
            droits de propriété intellectuelle.
          </div>
          <div className="cgu-section" id="cgu-obligations">
            <h2 className="cgu-section-title">Article 8 - Responsabilité</h2>
            <p className="cgu-section-text">
              Pour toutes les obligations qui lui incombent, Wyze Kids est tenue
              à une obligation de moyens et non de résultat.
            </p>
            <h3 className="cgu-section-subtitle">
              a) Accès et fonctionnement du Site
            </h3>
            <p className="cgu-section-text">
              Le Site repose sur des technologies développées par des tiers. Le
              Site s'engage à mettre en œuvre tous les moyens nécessaires pour
              assurer un accès continu au Site. Toutefois, en vue d'assurer la
              maintenance technique du Site, l'accès pourra être interrompu
              ponctuellement. Dans la mesure du possible, Wyze Kids s'efforcera
              d'en avertir les Utilisateurs via le Site.
            </p>
            <p className="cgu-section-text">
              Wyze Kids décline toute responsabilité en cas d'un éventuel
              préjudice qui pourrait résulter de l'indisponibilité du Site ou
              d'un problème de connexion au Site.
            </p>
            <p className="cgu-section-text">
              La responsabilité de Wyze Kids ne pourra être davantage engagée,
              sans que cette liste ne soit exhaustive, du fait de la perte de
              profits, de clientèle, de données ou informations stockées sur le
              Site dans le cadre d'un usage professionnel ou commercial, ni du
              fait de dommages indirects dans le cadre d'un usage à titre
              personnel.
            </p>
            <h3 className="cgu-section-subtitle">
              b) Contenu publié sur le Site
            </h3>
            <p className="cgu-section-text">
              Les informations fournies sur le Site, y compris l'interprétation
              des réglementations et des usages, ne le sont qu'à titre purement
              informatif. Pour un conseil personnalisé ou la recherche d'un
              texte législatif, réglementaire ou contractuel, l'Utilisateur se
              reportera aux informations officielles des services publics
              concernés.
            </p>
            <p className="cgu-section-text">
              La diffusion d'un Contenu édité par un Client, sur le Site ou par
              une Notification, ne veut pas dire que ce Contenu est recommandé
              par Wyze Kids. En conséquence, la responsabilité de Wyze Kids ne
              peut être engagée en cas de Contenu comportant des allégations
              fausses, incomplètes ou susceptibles d'induire en erreur.
            </p>
            <p className="cgu-section-text">
              Wyze Kids ne peut être tenu pour responsable des fausses
              déclarations faites par les Clients et les Utilisateurs Candidat.
              De même, Wyze Kids ne saurait être responsable si les informations
              communiquées par ces Utilisateurs Candidats et Clients au jour de
              leur Inscription ou de la publication du message concerné sont
              devenues obsolètes.
            </p>
            <p className="cgu-section-text">
              Les informations et contributions publiées sur le Site relèvent
              donc de la responsabilité exclusive des Utilisateurs Candidats et
              des Clients.
            </p>
            <p className="cgu-section-text">
              L'Utilisateur Candidat et le Client reconnaissent par ailleurs que
              Wyze Kids ne saurait être considéré comme responsable de la
              relation qui pourrait s'instaurer entre l'Utilisateur Candidat et
              le Client à la suite de l'utilisation des Services.
            </p>
            <p className="cgu-section-text">
              La responsabilité de Wyze Kids ne sera engagée que s'il est établi
              que le dommage dénoncé résulte de sa propre faute et sous réserve
              qu'il existe un lien de causalité direct et certain entre la faute
              alléguée et le dommage subi par l'Utilisateur.
            </p>
            <p className="cgu-section-text">
              Wyze Kids ne peut être tenu responsable de la perte ou de la
              destruction du Contenu.
            </p>
            <p className="cgu-section-text">
              Aucune responsabilité de Wyze Kids ne saurait découler de la
              violation des présentes conditions d'accès et d'utilisation du
              Site par un Utilisateur.
            </p>
            <p className="cgu-section-text">
              En tout état de cause, la responsabilité de Wyze Kids ou de tout
              autre exploitant futur, ne saurait excéder 150 €, tous chefs de
              préjudice confondus.
            </p>
          </div>
          <div className="cgu-section" id="cgu-prescriptions">
            <h2 className="cgu-section-title">Article 9 - Prescription</h2>
            <p className="cgu-section-text">
              Toute action en responsabilité contre Wyze Kids est prescrite un
              an après la survenance du fait dommageable générateur.
            </p>
          </div>
          <div className="cgu-section" id="cgu-divisibility-interpretations">
            <h2 className="cgu-section-title">
              Article 10 - Divisibilité - Interprétation
            </h2>

            <p className="cgu-section-text">
              Le fait pour Wyze Kids ou l'Utilisateur de ne pas exercer, à un
              moment quelconque, une prérogative reconnue par les présentes
              Conditions générales ne pourra en aucun cas être interprété comme
              une renonciation expresse ou tacite au droit d'exercer ladite
              prérogative à l'avenir.
            </p>
            <p className="cgu-section-text">
              Par ailleurs, la reconnaissance de l'invalidité d'une clause
              n'affectera pas la validité du reste des Conditions Générales et
              de toute autre clause.
            </p>
          </div>
          <div className="cgu-section">
            <h2 className="cgu-section-title">
              Article 11 - Loi applicable - Tribunal compétent
            </h2>
            <p className="cgu-section-text">
              Les présentes conditions, les annexes et tout document pris en
              application sont soumis à la loi française. Tout litige dans
              l'interprétation et l'exécution des présentes sera porté devant
              les juridictions de droit commun s'agissant d'un Utilisateur non
              professionnel et devant le tribunal de commerce de Bobigny,
              nonobstant pluralité de défendeurs ou appel en garantie,
              s'agissant des Utilisateurs professionnels.
            </p>
          </div>
        </div>
        <div className="cgu-footer">
          <span>
            <p>07 45 62 88 26</p>
            <p>
              <a
                href="mailto:secretariat@wyze-kids.com"
                aria-label="Contacter le secretariat via email"
              >
                <span className="bold unerline">secretariat@wyze-kids.com</span>
              </a>
            </p>
            <p>Siège social : 21 avenue Raspail 93420 VILLEPINTE </p>
          </span>
        </div>
      </div>
    </div>
  );
}

export default CguPage;
