import { useRef, useEffect, useState } from "react";
import { Parallax, Background } from "react-parallax";
import "./HeroSection.css";
import IconNext from "../../../../media/icons/IconNext.jsx";
import heroImage from "../../../../media/images/home-hero-bgImage-right-cropped.jpg";

function HeroSection() {
  /*animation d'apparition sur intersection*/
  const sectionRef = useRef();
  const [isSectionIntersected, setIsSectionIntersected] = useState();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsSectionIntersected(entry.isIntersecting);
    });
    observer.observe(sectionRef.current);
    return () => {
      observer.disconnect();
    };
  });

  return (
    <Parallax
      strength={400}
      bgImage={heroImage}
      bgImageStyle={{
        backgroundPosition: "center right",
        width: "100%",
        height: "100%",
      }}
      bgStyle={{ backgroundPosition: "center right", border: "red solid" }}
      className="parallax"
    >
      <div className="hero-section-wrapper">
        <div
          ref={sectionRef}
          className={`hero-section-container ${
            isSectionIntersected ? "visible" : ""
          }`}
        >
          <div className="hero-section-body">
            <h1 className="hero-section-title bold">Wyze Kids</h1>
            <h6 className="hero-section-slogan">Explore, Create, Innovate</h6>
            <a href="#formation-section" className="hero-section-button">
              <span className="hero-section-button-weight">Nos formations</span>
              <IconNext rotation="rotate-down" width={22} height={22} />
            </a>
          </div>
        </div>
      </div>
    </Parallax>
  );
}

export default HeroSection;
